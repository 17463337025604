import { IDeath } from '@interfaces/IDeath';
export const deathDataInitialState: IDeath = {
    funeralCareContract: {
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        contactPerson: '',
        dateOfSignature: '',
        comment: '',
    },
    graveSiteCertificate: {
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        dateOfSignature: '',
    },
    funeralWishes: {
        burialWish: '',
        graveSite: '',
        notes: '',
    },
    funeralInsurance: {
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        policyNumber: '',
        sumInsured: '',
        effectivenessWaitingPeriod: '',
        dateOfConclusion: '',
        additionalBenefits: [],
    },
    custodyOrder: {
        documentType: '',
        dateOfSignature: '',
        placeOfSignature: '',
    },
    lastWill: {
        placeWhereTheWillIsKept: '',
        dateOfCreation: '',
        dateOfLastChange: '',
        comment: '',
    },
};
