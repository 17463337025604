import React, { useEffect } from 'react';
import { useUser } from '@contexts/UserProvider';
import useContactsStore from '@stores/useContactsStore';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import { useWizardStore } from '@stores/useWizzardStore';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MainLayout from './MainLayout';

const Wizard = React.lazy(() => import('../../pages/Wizard/Wizard'));
const Documents = React.lazy(() => import('../../pages/Documents/Documents'));
const Contacts = React.lazy(() => import('../../pages/Contacts/Contacts'));
const Account = React.lazy(() => import('../../pages/Account/Account'));

const AuthenticatedLayout = () => {
    const user = useUser();
    const { fetchUserDocuments } = useWizardStore();
    const { fetchUserSettings, fetchUserSettingsDetails, hideNavigation } = useUserSettingStore();
    const { fetchContacts, getInviterByInvitationCode, setInviter, getAccountProfiles, setActiveAccount, activeAccount, isSharedAccount } = useContactsStore();
    const token = user.currentUser?.accessToken;
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            void fetchContacts(token);
            void fetchUserDocuments(token);
            void fetchUserSettings(token);
            void fetchUserSettingsDetails(token);
            void getAccountProfiles(token);
        }
    }, [user.currentUser?.accessToken]);

    useEffect(() => {
        const invitationCode = sessionStorage.getItem('invitationCode');

        const getInviter = async () => {
            if (invitationCode) {
                const inviter = await getInviterByInvitationCode(invitationCode, token!);
                setInviter(inviter);
            }
        };

        void getInviter();
    }, []);

    useEffect(() => {
        const accountId = user.currentUser?.accountId;

        if (accountId) {
            setActiveAccount(accountId);
        }
    }, []);

    useEffect(() => {
        const accountId = user.currentUser?.accountId;

        if (accountId) {
            setActiveAccount(accountId);
        }
    }, []);

    useEffect(() => {
        if (isSharedAccount) {
            void fetchUserDocuments(token!);
            navigate('/documents');
        }
    }, [activeAccount]);

    return (
        <MainLayout hideNavigation={hideNavigation}>
            <Routes>
                <Route path="/wizards" element={<Wizard />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/account/:section" element={<Account />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </MainLayout>
    );
};

export default AuthenticatedLayout;
