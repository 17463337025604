import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { IModal } from '../../interfaces/IModal';

export default function Modal({ openModal, onClose, title, text, confirmButtonText, children, maxWidth, hasCloseButton, hasBottomCloseButton = true }: IModal) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <Dialog fullWidth maxWidth={maxWidth} fullScreen={fullScreen} open={openModal} onClose={onClose} aria-labelledby="responsive-dialog-title">
            {children ? (
                <>
                    <DialogContent>{children}</DialogContent>
                    {hasCloseButton && (
                        <DialogActions>
                            <Button onClick={() => onClose(false)} autoFocus>
                                {t('common.close_button_label')}
                            </Button>
                        </DialogActions>
                    )}
                </>
            ) : (
                <>
                    <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{text}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {hasBottomCloseButton && (
                            <Button onClick={() => onClose(false)} autoFocus>
                                {confirmButtonText}
                            </Button>
                        )}
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
