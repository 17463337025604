import * as React from 'react';
import { useEffect } from 'react';
import { AppBar, Box, Toolbar, Container, Avatar, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@assets/images/settings.svg';
import NotificationIcon from '@assets/images/notification_off.svg';
import MenuComponent from '@components/menu/Menu';
import * as Styled from './styles';
import theme from 'src/theme';
import { useNavigate } from 'react-router-dom';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import { useContactsStore } from '@stores/useContactsStore';
import NotificationMenu from '@components/menu/NotificationMenu';
import ProfileMenu from '@components/menu/ProfileMenu';

interface IAHeader {
    hideNavigation?: boolean;
}
const AppHeader: React.FC<IAHeader> = ({ hideNavigation }) => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [menuId, setMenuId] = React.useState('');
    const { t } = useTranslation();
    const { tab, setTab } = useUserSettingStore();
    const { isSharedAccount, accountContact, contactAvatars } = useContactsStore();

    const navigate = useNavigate();
    let fullName = ``;
    const contactAvatar = contactAvatars.find((avatar) => avatar.contactId === accountContact?.id);

    if (accountContact) {
        fullName = `${accountContact.firstName} ${accountContact.lastName}`;
    }

    const handleTabChange = (event: React.SyntheticEvent) => {
        const translationKey = (event.currentTarget as HTMLElement).dataset.key;
        if (translationKey?.startsWith('account')) {
            return;
        }
        setTab(translationKey!);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>, id: string) => {
        setAnchorElUser(event.currentTarget);
        setMenuId(id);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        setMenuId('');
    };

    const isMenuOpen = (id: string) => menuId === id;

    const settingsItems = [
        {
            label: 'Account',
            onClick: () => {
                navigate('/account/account');
                handleCloseUserMenu();
                setTab('');
            },
        },
        {
            label: 'General',
            onClick: () => {
                navigate('/account/general');
                handleCloseUserMenu();
                setTab('');
            },
        },
    ];

    const pages = [
        { key: 'wizards', label: t('wizards'), disabled: isSharedAccount ? true : false, onClick: () => navigate('/wizards', { replace: true, state: {} }) },
        { key: 'documents', label: t('documents'), disabled: false, onClick: () => navigate('/documents', { replace: true, state: {} }) },
        { key: 'contacts', label: t('contacts'), disabled: isSharedAccount ? true : false, onClick: () => navigate('/contacts', { replace: true, state: {} }) },
    ];

    useEffect(() => {
        const path = window.location.pathname.replace('/', '');
        const activeTab = `${path.charAt(0).toUpperCase()}${path.slice(1)}`;
        if (activeTab.toLowerCase().startsWith('account')) {
            setTab('');
            return;
        }
        setTab(activeTab);
    }, []);

    const selectedTab = tab.toLowerCase();

    const avatarString = (name: string) => {
        if (!name) return null;
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    };

    useEffect(() => {
        if (isSharedAccount) setTab('documents');
    }, [isSharedAccount]);

console.log('AppHeader', contactAvatar, accountContact)

    return (
        <AppBar
            position="static"
            color="secondary"
            sx={{
                boxShadow: 'none',
                borderBottom: '1px solid #D7D7F0',
                background: theme.palette.common.white,
                '@media print': {
                    display: 'none',
                },
            }}
        >
            <Container
                sx={{
                    maxWidth: '1366px !important',
                }}
                id="top"
            >
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: { xs: hideNavigation ? 'flex' : 'none', md: 'flex' } }}>
                        <Styled.Logo aria-label="logo" />
                    </Box>
                    {!hideNavigation && (
                        <>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <MenuComponent
                                    tooltip={t('open_menu')}
                                    button={<MenuIcon />}
                                    menuItems={pages}
                                    anchorEl={anchorElUser}
                                    open={isMenuOpen('menu')}
                                    onClose={handleCloseUserMenu}
                                    onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'menu')}
                                />
                            </Box>
                            <Box sx={{ flexGrow: 1, ml: 6, display: { xs: 'none', md: 'flex' } }}>
                                <Tabs onChange={handleTabChange} value={selectedTab ? t(selectedTab.toLowerCase()).toString() : false} aria-label="tabs">
                                    {pages.map((page) => (
                                        <Styled.MenuTab
                                            key={page.key}
                                            data-key={page.key}
                                            value={page.label}
                                            label={page.label}
                                            onClick={page.onClick}
                                            disabled={page.disabled}
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                            <Styled.Search>
                                <Styled.SearchIconWrapper>
                                    <SearchIcon />
                                </Styled.SearchIconWrapper>
                                <Styled.StyledInputBase placeholder={t('search')} inputProps={{ 'aria-label': 'search' }} />
                            </Styled.Search>

                            <NotificationMenu
                                tooltip={t('open_notifications')}
                                button={<img src={NotificationIcon} />}
                                buttonStyle={{ width: '40px', height: '40px', backgroundColor: '#F5F7FA', marginRight: '20px' }}
                                anchorEl={anchorElUser}
                                open={isMenuOpen('notifications')}
                                onClose={handleCloseUserMenu}
                                onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'notifications')}
                            />
                            <MenuComponent
                                width={286}
                                tooltip={t('open_settings')}
                                button={<img alt="settings" src={SettingsIcon} />}
                                buttonStyle={{ width: '40px', height: '40px', backgroundColor: '#F5F7FA', marginRight: '20px' }}
                                menuItems={settingsItems}
                                anchorEl={anchorElUser}
                                open={isMenuOpen('settings')}
                                onClose={handleCloseUserMenu}
                                onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'settings')}
                            />
                            <ProfileMenu
                                tooltip={fullName}
                                avatar={contactAvatar?.avatarImage}
                                button={<Avatar alt={fullName} {...(avatarString(fullName) ?? '')} src={contactAvatar?.avatarImage} />}
                                anchorEl={anchorElUser}
                                open={isMenuOpen('user')}
                                onClose={handleCloseUserMenu}
                                onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'user')}
                            />
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default AppHeader;
